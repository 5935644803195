"use client";
import React, { ReactNode } from "react";
import { motion } from "framer-motion";

const Transition = ({ children }: { children: ReactNode }) => {
	return (
		<motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8 }}>
			{children}
		</motion.div>
	);
};

export default Transition;
